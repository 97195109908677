//----- Colors -----

$color-black: #000000;
$color-blue: #1ba7e0;
$color-dark: #323232;
$color-green: #649a70;
$color-orange: #e7b258;
$color-red: #ff6866;
$color-white: #ffffff;

//----- Fonts -----

$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semiBold: 600;
$font-bold: 700;

$font-size-s: 1.4rem;
$font-size-m: 1.6rem;
$font-size-l: 1.8rem;
$font-size-xl: 2.1rem;
$font-size-xll: 4.8rem;

$line-height-s: 2rem;
$line-height-m: 2.6rem;
$line-height-l: 2.8rem;
$line-height-xl: 3rem;
$line-height-xll: 7.5rem;

//----- Margins -----

$margin-s: 10px;
$margin-m: 20px;
$margin-l: 50px;
$margin-xl: 100px;
$margin-xxl: 140px;

//----- Paddings -----

$padding-s: 10px;
$padding-m: 20px;
$padding-l: 30px;
$padding-xl: 50px;
$padding-xxl: 100px;
