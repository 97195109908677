@import '../../theme/variables';

@keyframes arrowMove {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}

.landing {
  display: flex;
  justify-content: center;
  height: 100vh;

  &__arrowBox {
    position: absolute;
    bottom: 20px;
    width: 40px;
    height: 25px;
    transform: rotate(180deg);
  }

  &__arrow {
    z-index: 1000;
    animation: arrowMove 2s infinite;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__title {
    color: $color-white;
    font-size: $font-size-xll;
    font-weight: $font-bold;
    position: absolute;
    top: 50%;
    text-align: center;
    text-shadow: 4px 4px 9px rgba(0, 0, 0, 0.8);
  }
  &__paragraph {
    @extend .landing__title;

    font-size: $font-size-xl;
    top: 55%;
  }
}
