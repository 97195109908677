@import '../../theme/variables';

.footer {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  margin: auto;
  max-width: 975px;
  position: relative;
  font-weight: $font-semiBold;
  font-size: $font-size-m;
  line-height: $line-height-m;
  margin-top: $margin-l;
  margin-bottom: $margin-s;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #dde1e3;
  }

  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: $margin-m;
  }

  &__name {
    font-size: $font-size-l;
    line-height: $line-height-l;
  }

  &__address {
    font-weight: $font-regular;
  }

  &__copyright {
    padding-top: $padding-l;
    color: #7e8d94;
    font-weight: $font-medium;
    font-size: $font-size-s;
    line-height: $line-height-s;
    text-align: center;
    text-decoration-line: underline;
  }
}
