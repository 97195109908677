.header {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  height: 136px;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    max-width: 1320px;
    height: 90px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
