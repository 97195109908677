@import '../../theme/variables';

.video {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 975px;
  position: relative;
  padding-top: $padding-xxl;
  padding-bottom: $padding-xxl;

  &__title {
    font-weight: $font-bold;
    font-size: $font-size-xl;
    margin-bottom: $margin-s;
    text-align: center;
  }

  &__paragraph {
    font-weight: $font-regular;
    font-size: $font-size-s;
    text-align: center;
  }

  &__box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    max-width: 954px;
    margin-top: $margin-l;
    margin-bottom: $margin-xl;
  }

  &__iframe {
    content-visibility: auto;
    width: 300px;
    height: 150px;

    @media screen and (min-width: 620px) {
      width: 600px;
      height: 300px;
    }

    @media screen and (min-width: 920px) {
      width: 900px;
      height: 450px;
    }
  }
}
