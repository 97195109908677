.navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 678px;
  margin-left: auto;
  margin-right: auto;

  &__arrow {
    transform: rotate(180deg);
    margin-left: 5px;
  }

  &__item {
    list-style: none;
  }

  &__link {
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
  }
}
